/*global fbq*/

var TRACKING_CATEGORY = 'Search';

// There isn't a payload requirement for the search event.  The search query might be
// useful, so we send that.

$(document).ready(function() {
    var keywords = $('.search-keywords').first().text().trim();

    // Using the menu naviation triggers this tracking event, ignore those.
    if (keywords) {
        console.log('Search keywords', keywords);
        fbq('track', TRACKING_CATEGORY, { keywords: keywords });
    }
});
